import { useRef, useState } from "react";
import Button from "../Util/Button";
import Loading from "../Util/Loading";
import UserInput from "./UserInput";
import UserSelect from "./UserSelect";
import "../../Styles/users.scss";

export default function NewPortalUser(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const fname = useRef();
  const sname = useRef();
  const email = useRef();
  const phone = useRef();
  const level = useRef();
  const partner = useRef();
  const role = useRef();
  const password = useRef();
  const cpassword = useRef();
  const [stakeholderList, setStakeholderList] = useState();
  const [selectedStakeholder, setSelectedStakeholder] = useState();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Name:
        titleCase(fname.current.getValue().trim()) +
        " " +
        titleCase(sname.current.getValue().trim()),
      Email: email.current.getValue().toLowerCase().trim(),
      Phone: phone.current.getValue(),
      Level: level.current.getValue(),
      Partner: partner.current.getValue(),
      Password: password.current.getValue(),
      cpassword: cpassword.current.getValue(),
      Role: role.current.getValue(),
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !validatePassword(body.Password) ||
        !validatePassword(body.cpassword)
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (body.Password !== body.cpassword) {
        result = false;
        setError("Passwords do not match!!!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (!body.Name) {
        result = false;
        setError("Name is reqired!");
        setLoading(false);
        return result;
      }
      if (!body.Level) {
        result = false;
        setError("Role field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Role) {
        result = false;
        setError("Role is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/portal/Users";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const searchStakeholder = (q) => {
    fetch(`/api/partners/quicksearch/${q}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setStakeholderList(data.data);
      })
      .catch((e) => {});
  };

  const BenList = (params) => {
    return (
      <div
        onClick={() => {
          setSelectedStakeholder(params.item);
          setStakeholderList(null);
        }}
        className="so_list"
      >
        <h4>{params.item.Name}</h4>
        <hr height="1" />
      </div>
    );
  };

  return (
    <div className="users">
      <div className="list">
        <h3>System Users</h3>
        <hr />
        <div className="new">
          <h6>{error}</h6>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <UserInput ref={fname} type="text" label="First Name *" />
              <UserInput ref={sname} type="text" label="Surname *" />
            </div>

            <div className="div2equal">
              <UserInput ref={email} type="email" label="Email *" />
              <UserInput ref={phone} type="number" label="Phone *" />
            </div>

            <div className="div2equal">
              <UserSelect
                ref={role}
                label="Role *"
                data={["Staff", "Partner", "Admin"]}
              />
              <UserSelect
                ref={level}
                label="Access Level *"
                data={["Full Access", "Mobile", "Portal"]}
              />
            </div>

            <div className="search">
              <UserInput
                type="text"
                ref={partner}
                handleChange={(e) => {
                  searchStakeholder(e);
                }}
                label="Partners Name *"
                hint="Type Partners Name to search"
                value={
                  selectedStakeholder?.Name && selectedStakeholder.Name
                }
              />
              {stakeholderList && stakeholderList?.length > 0 && (
                <div className="search_list">
                  {stakeholderList?.map((item, i) => {
                    return <BenList key={i} item={item} />;
                  })}
                </div>
              )}
            </div>

            <div className="div2equal">
              <UserInput ref={password} type="password" label="Password *" />
              <UserInput
                ref={cpassword}
                type="password"
                label="Confirm Password *"
              />
            </div>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
}
