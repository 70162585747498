import React, { useState } from "react";
import "../../Styles/userhome.scss";
import Outputs from "./Outputs";
import Indicators from "./Indicators";
import Tables from "../Performance/Tables";

export default function Mel(props) {
  const [active, setActive] = useState("Outputs");

  return (
    <div className="userhome">
      <div className="vtop">
        <Item txt="Outputs" active={active} setActive={setActive} />
        <Item txt="Update Indicators" active={active} setActive={setActive} />
        <Item txt="IPTT" active={active} setActive={setActive} />
      </div>
      {active === "Outputs" && <Outputs />}
      {active === "Update Indicators" && <Indicators />}
      {active === "IPTT" && <Tables />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
