import { useEffect, useState } from "react";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import "../../Styles/valuechain.scss";
import WaveLoading from "../Util/WaveLoading";
import Group from "./Group";
import { isMobile } from "react-device-detect";
import EachSection from "./EachSection";

export default function Sections(props) {
  const [refresh, setRefresh] = useState(false);
  const [value, setValue] = useState(null);

  return (
    <div className="valuechain">
      <EachSection
        title={props.vc.replace("%20", " ").replace("&%20", " & ")}
        setRefresh={setRefresh}
        refresh={refresh}
        setValue={setValue}
        value={value}
      />
    </div>
  );
}
