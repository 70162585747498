import React from "react";

export default class IndicatorsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.value ||
        (this.props.data && this.props.data.length > 0
          ? this.props.data[0]
          : ""),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data.length > 0) {
      this.setState({ value: this.props.value || this.props.data[0] });
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const { label, handleSelection, data } = this.props;
    return (
      <div className="select">
        <label htmlFor="input">{label}</label>
        <select
          onChange={(e) => {
            const selectedValue = e.target.value;
            this.setState({ value: selectedValue });
            if (handleSelection) {
              handleSelection(selectedValue);
            }
          }}
          value={this.state.value}
        >
          {data &&
            data.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      </div>
    );
  }
}
