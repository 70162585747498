import React from "react";
import { useState, useEffect } from "react";
import "../../Styles/userhome.scss";
import Partners from "./Partners";

export default function PartnersHome(props) {
  const [active, setActive] = useState("");

  return (
    <div className="userhome">
      <div className="">
        <Item txt="" active={active} setActive={setActive} />
      </div>
      {active == "" && <Partners />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active == props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
