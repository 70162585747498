import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import "swiper/css"; // Import all Swiper CSS
import "swiper/css/pagination";

import lg1 from "../../assets/imgs/lg1.jpg";
import lg2 from "../../assets/imgs/lg2.jpg";
import lg3 from "../../assets/imgs/lg3.jpg";
import lg4 from "../../assets/imgs/lg4.jpg";
import lg5 from "../../assets/imgs/lg5.jpg";
import lg6 from "../../assets/imgs/lg6.jpg";
import lg7 from "../../assets/imgs/lg7.jpg";
import lg8 from "../../assets/imgs/lg8.jpg";
import lg9 from "../../assets/imgs/lg9.jpg";
import lg10 from "../../assets/imgs/lg10.jpg";

export default function RightPanel(props) {
  const content = [lg1, lg2, lg3, lg4, lg5, lg6, lg7, lg8, lg9, lg10];
  return (
    <div className="right_panel">
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        pagination={{
          clickable: false, // You can configure pagination options here
        }}
        className="mySwiper"
      >
        {content.map((item, i) => (
          <SwiperSlide key={i}>
            <img src={item} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
