import React, { useEffect, useState } from "react";
import Header from "../components/Util/Header2";
import "../Styles/landingNew.scss";
import DiagonalRectangle from "../components/Util/DiagonalRectangle";
import Footer from "../components/Util/footer";
import agrovethome from "../assets/imgs/agrovetshome.jpg";
import practitionershome from "../assets/imgs/Practititionerhome.jpg";
import equinehome from "../assets/imgs/equinehome.jpg";
import communityhome from "../assets/imgs/communityhome.jpg";
import schoolshome from "../assets/imgs/schoolshome.jpg";
import farrierhome from "../assets/imgs/lg8.jpg";

export default function LandingNew(props) {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    fetch(`/api/data/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => {});
  }, []);

  const amfpage = () => {
    window.location.href = "/AMF";
  };
  const fmfpage = () => {
    window.location.href = "/FMF";
  };
  const ahmfpage = () => {
    window.location.href = "/AHMF";
  };
  const partners = () => {
    window.location.href = "/partners";
  };

  const gis = () => {
    window.location.href = "/gis";
  };

  return (
    <div className="LandingNew">
      <div className="sections">
        <div className="left">
          <div className="content">
            <TopItem
              title="Practitioners"
              color="grey"
              link="/animalhealth/Practitioners"
              amt={stats ? stats.Practitioners : 0}
              imageSrc={practitionershome}
            />

            <TopItem
              title="Farriers"
              color="grey"
              link="/animalhealth/Farriers"
              amt={stats ? stats.Farriers : 0}
              imageSrc={communityhome}
            />

            <TopItem
              title="Equine Owners"
              color="grey"
              link="/communityengagement/Equineowners"
              amt={stats ? stats.Equineowners : 0}
              imageSrc={equinehome}
            />
            <TopItem
              title="Community Groups"
              color="grey"
              link="/communityengagement/Communitygroups"
              amt={stats ? stats.Communitygroups : 0}
              imageSrc={farrierhome}
            />

            <TopItem
              title="Care Clubs"
              color="grey"
              link="/communityengagement/Careclubs"
              amt={stats ? stats.Careclubs : 0}
              imageSrc={schoolshome}
            />

            <TopItem
              title="Agrovets"
              color="grey"
              amt={stats ? stats.Agrovets : 0}
              link="/animalhealth/Agrovets"
              imageSrc={agrovethome}
            />
          </div>
        </div>
        <div className="right">
          <div className="content-right">
            <div className="content1">
              <h5>Agrovets Monitoring Framework</h5>
              <div className="narration">
                <div className="text-content">
                  <p>
                    This comprehensive framework focuses on building skills,
                    addressing gaps, and strengthening inter-professional
                    relationships within the agrovet industry. By assessing the
                    skills and competencies of agrovet practitioners through
                    mentoring and constructive feedback, the AMF aims to improve
                    performance and enhance frontline animal health services.
                  </p>
                </div>
                <DiagonalRectangle handleClick={amfpage} label="AMF" />
              </div>
            </div>
            <div className="content1">
              <h5>Farriers Monitoring Framework</h5>
              <div className="narration">
                <div className="text-content">
                  <p>
                    This aims to raise the profile of farriery as a trade, to
                    encourage development of professional standards, and as a
                    result to improve hoof health for millions of animals and
                    support sustainable livelihoods for millions of families.
                    Through training local farriers, we are helping them to
                    improve their farriery skills while also supporting them to
                    be excellent mentors and trainers.
                  </p>
                </div>
                <DiagonalRectangle handleClick={fmfpage} label="FMF" />
              </div>
            </div>
            <div className="content1">
              <h5>Animal Health Monitoring Framework</h5>
              <div className="narration">
                <div className="text-content">
                  <p>
                    The Animal Health Mentoring Framework (AHMF) is a list of
                    essential skills and competencies required to provide good
                    quality animal health services. It is used by specially
                    trained vets to assess and improve the individual skill and
                    competency levels of animal health providers (AHPs).
                  </p>
                </div>
                <DiagonalRectangle handleClick={ahmfpage} label="AHMF" />
              </div>
            </div>
            <div className="content1">
              <h5>Partners' Data</h5>
              <div className="narration">
                <div className="text-content">
                  <p>
                    This section provides details of all partners working with
                    Brooke East Africa. It also includes functionalities to
                    update partner details and add new partner.
                  </p>
                </div>
                <DiagonalRectangle handleClick={partners} label="Partners" />
              </div>
            </div>
            <div className="content1">
              <DiagonalRectangle label="Map" handleClick={gis} />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

const TopItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      className="tp_item"
      onClick={() => {
        window.location.href = props.link;
      }}
    >
      <img src={props.imageSrc} alt={props.title} />
      <p>{props.title}</p>
      <h1>{withCommas(props.amt)}</h1>
    </div>
  );
};
