import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/performance.scss";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Select from "../Util/Select";
import IndicatorsSelect from "../Util/IndicatorsSelect";

export default function Performance() {
  const cross = useRef();
  const urban = useRef();
  const rural = useRef();
  const wrm = useRef();
  const fps = useRef();
  const pg = useRef();

  return (
    <div className="indicators">
      <div className="top">
        <div className="title">
          <p className="sub-title">Indicator Perfomance Summary</p>
        </div>
      </div>
      <div ref={cross}>
        <IndicatorComponent
          title="Cross Cutting Indicators"
          url="Cross Cutting"
          link="crosscutting"
        />
      </div>
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      onClick={() => {
        props?.item?.current.scrollIntoView({ behavior: "smooth" });
      }}
      className="tp_item"
    >
      <h6>
        Last Update: <br />
        {props.date}
      </h6>
      <h4>{props.component}</h4>
      <FontAwesomeIcon className="p" icon={faArrowRight} />
    </div>
  );
};

const IndicatorComponent = (props) => {
  const [indicators, setIndicators] = useState(null);
  const [year, setYear] = useState("2023");
  const [active, setActive] = useState(year);
  const [quarter, setQuarter] = useState("Q1");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  // Function to generate year range
  const generateYearRange = () => {
    const currentYear = new Date().getFullYear();
    const myyears = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      myyears.push(String(i));
    }
    return myyears;
  };

  useEffect(() => {
    const today = new Date();
    setYear(today.getFullYear());
    setStart(`${today.getFullYear() - 1}-10-01`);
    setEnd(`${today.getFullYear()}-09-30`);
    if (start != "" && end != "") {
      getData();
    }
  }, []);

  useEffect(() => {
    const adjustedYear = parseInt(year) + 1;
    switch (active) {
      case "Q1":
        setStart(`${year}-04-01`);
        setEnd(`${year}-06-30`);
        setQuarter("Q1");
        break;
      case "Q2":
        setStart(`${year}-07-01`);
        setEnd(`${year}-09-30`);
        setQuarter("Q2");
        break;
      case "Q3":
        setStart(`${year}-10-01`);
        setEnd(`${year}-12-31`);
        setQuarter("Q3");
        break;
      case "Q4":
        setStart(`${adjustedYear}-01-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Q4");
        break;
      case year:
        setStart(`${year}-04-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Annual");
        break;
      default:
        break;
    }
  }, [active, year]);

  useEffect(() => {
    if (start != "" && end != "") {
      getData();
    }
  }, [start, end]);

  function getData() {
    setLoading(true);
    fetch(`/api/performance/indicators/joined/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setIndicators(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const handleYearChange = (v) => {
    setYear(v);
    setActive(v);
    // Call any additional functions to update places here
  };

  return (
    <div className="indicator_section">
      <div className="filter">
        <button
          onClick={() => {
            setActive("Q1");
          }}
          className={active == "Q1" ? "active" : ""}
        >
          Q1
        </button>
        <button
          onClick={() => {
            setActive("Q2");
          }}
          className={active == "Q2" ? "active" : ""}
        >
          Q2
        </button>
        <button
          onClick={() => {
            setActive("Q3");
          }}
          className={active == "Q3" ? "active" : ""}
        >
          Q3
        </button>
        <button
          onClick={() => {
            setActive("Q4");
          }}
          className={active == "Q4" ? "active" : ""}
        >
          Q4
        </button>
        <button
          onClick={() => {
            setActive(year);
          }}
          className={active == year ? "active" : ""}
        >
          {year}
        </button>

        <IndicatorsSelect
          handleSelection={handleYearChange}
          value={year}
          data={[String(new Date().getFullYear()), ...generateYearRange()]}
          label="Select Year"
        />
      </div>

      {isMobile ? (
        <div className="sbody" style={{ gridTemplateColumns: `1fr 0.5fr ` }}>
          <h3>Target</h3>
          <h3>Perform</h3>
        </div>
      ) : (
        <div
          className="sbody"
          style={{ gridTemplateColumns: ` 1fr 0.2fr 0.2fr 0.2fr` }}
        >
          <h3>Indicator</h3>
          <h3>Target</h3>
          <h3>Performance</h3>
          <h3>Achievement</h3>
        </div>
      )}

      <div className="ind_list">
        {indicators &&
          indicators.data.length > 0 &&
          indicators.data.map((item, i) => {
            return <Item key={i} item={item} active={quarter} year={year} />;
          })}
      </div>
      {loading && <Loading />}
    </div>
  );
};

const Item = (props) => {
  const [performance, setPerformance] = useState(0);
  const [achievement, setAchievement] = useState(0);

  const dynamicProperty = `Target_${props.active}`;

  function withCommas(x) {
    if (x == null) return 0;
    const p = x?.toString();
    if (p?.indexOf("$") != -1 || p?.indexOf("%") != -1) {
      return p?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      if (parseFloat(x) < 1) {
        let o = Math.round(parseFloat(x) * 10) / 10;
        return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        let o = Math.round(parseFloat(x));
        return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }

  useEffect(() => {
    if (props.item) {
      setPerformance(props.item.Performance ? props.item.Performance : 0);
      let p = props.item.Performance;
      const target = parseInt(props.item[dynamicProperty]);

      if (target != 0) {
        let pc = (p / target) * 100;
        if (pc < 1) {
          setAchievement(pc.toFixed(1));
        } else {
          setAchievement(pc.toFixed(0));
        }
      }
    }
  }, [props.item]);

  return (
    <div
      onClick={() => {
        window.location.href = "/indicators/singleview/" + props.item.ID;
      }}
    >
      {isMobile ? (
        <div className="tbody" style={{ gridTemplateColumns: `1fr .5fr .5fr` }}>
          <p htmlFor="">{props.item.Code}</p>
          <p>{withCommas(props.item[`Target${props.year}`])}</p>

          <p className="achievement">
            {achievement < 50 && (
              <FontAwesomeIcon color="red" icon={faArrowDown} className="ic" />
            )}
            {achievement >= 50 && achievement < 75 && (
              <FontAwesomeIcon color="red" icon={faArrowRight} className="ic" />
            )}
            {achievement >= 75 && (
              <FontAwesomeIcon color="green" icon={faArrowUp} className="ic" />
            )}{" "}
            {withCommas(achievement)} %
          </p>
        </div>
      ) : (
        <div
          className="tbody"
          style={{ gridTemplateColumns: ` 1fr 0.2fr 0.2fr 0.2fr` }}
          onClick={() => {}}
        >
          <p className="ind-desc">{props.item.Description}</p>

          <h4>{props.item[dynamicProperty]}</h4>
          <h5 className="result">{props.item.Performance}</h5>

          <p className="achievement">
            {achievement}%
            {achievement < 50 && (
              <FontAwesomeIcon color="red" icon={faArrowDown} className="ic" />
            )}
            {achievement >= 50 && achievement < 75 && (
              <FontAwesomeIcon
                color="amber"
                icon={faArrowRight}
                className="ic"
              />
            )}
            {achievement >= 75 && (
              <FontAwesomeIcon color="green" icon={faArrowUp} className="ic" />
            )}{" "}
          </p>
        </div>
      )}
    </div>
  );
};
