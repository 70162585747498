import React from "react";
import FooterItem from "./footerItem";
import "../../Styles/_footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer" id="footer">
        <div className="overlay"></div>
        <div className="container">
          <FooterItem
            title="Brooke East Africa"
            info="Hill Lane Off Mara Road 
            P.O Box 43220 - 00100  
            Nairobi, Kenya"
            mail="brookeea@thebrookeeastafrica.org"
          />

          <FooterItem
            title="LEGAL"
            i1="Terms and Conditions"
            link1="/terms"
            i2="Privacy Policy"
            link2="/privacypolicy"
          />
          <FooterItem
            title="Quick Links"
            i1="FAQs"
            link1="/faqs"
            ff="Give feedback"
          />
          <FooterItem
            title="CONTACTS"
            mail="brookeea@thebrookeeastafrica.org"
            num1="+254 700 307 709"
           
          />
        </div>
        <span className="copyright">Copyright 2022. All rights reserved.</span>
      </div>
    );
  }
}

export default Footer;
