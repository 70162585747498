import {
  faEdit,
  faPencilAlt,
  faTimes,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";

export default function SelectedOutput(props) {
  const [clicked, setClicked] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [selIndics, setSelIndics] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    props.setLoading(true);
    fetch(`/api/outputs/joined/${props.indicatorDetails}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        props.setLoading(false);
        setData(data?.data[0]);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  }, [refresh, props.indicatorDetails]);

  useEffect(() => {
    props.setLoading(true);
    fetch(`/api/outputs/indicators/${props.indicatorDetails}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        props.setLoading(false);
        setSelIndics(data?.data || []);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  }, [refresh, props.indicatorDetails]);

  function deleteOutput() {
    props.setLoading(true);
    fetch(`/api/outputs/${data?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        setTimeout(() => {
          props.setRefresh(!props.refresh);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const openSucess = () => {
    setShowSuccess(true);
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>
          <strong>Goal:</strong> {data?.Goal}
        </p>

        <p>
          <strong>Strategic Output:</strong> {data?.SO}
        </p>
        <p>
          <strong>Intermediate Output::</strong> {data?.IO}
        </p>
        <p>
          <strong>Selected Output::</strong> {data?.Output}
        </p>

        <br />
        <h4>
          <strong>Indicators of Selected Output</strong>
        </h4>
        <hr />
        {selIndics?.length > 0 ? (
          selIndics?.map((indicator, index) => (
            <div key={index}>
              <p>
                <u>Indicator {index + 1}:</u>
              </p>
              <p>
                <strong>Name:</strong> {indicator.Description}
              </p>
              <p>
                <strong>Annual Target:</strong> {indicator.Target}
              </p>
            </div>
          ))
        ) : (
          <p>No indicators available</p>
        )}
      </div>
      <hr />
      <div className="actions">
        <h6
          onClick={() => {
            setActive("Update Details");
            setToggleBasic(true);
          }}
          className={active === "Update Details" ? "active" : ""}
        >
          <FontAwesomeIcon className="fa" icon={faEdit} />
          Update Output
        </h6>
        <h6
          onClick={() => {
            openConfirm();
          }}
        >
          <FontAwesomeIcon className="fa" icon={faTrash} />
          Delete Output
        </h6>
      </div>

      {toggleBasic && (
        <UpdateOutput
          setClicked={setToggleBasic}
          setRefresh={setRefresh}
          refresh={refresh}
          partnerID={props.partnerName}
          ID={data?.ID}
          Name={data?.Output}
        />
      )}
      {showConfirm && (
        <Confirm
          closeConfirm={closeConfirm}
          deleteFunction={deleteOutput}
          message="you want to delete?"
          action="delete"
        />
      )}
      {showSuccess && (
        <ConfirmSuccess
          closeConfirm={closeSuccess}
          action="deleted"
          type="Output"
        />
      )}
    </div>
  );
}

const UpdateOutput = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const description = useRef();

  const updateOutput = () => {
    const body = {
      Description: description.current.getValue(),
    };

    setError("");
    setLoading(true);

    fetch(`/api/outputs/${props.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to create ");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Update Output Description</h3>
          <FontAwesomeIcon
            onClick={() => props.setClicked(false)}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <hr />
        <div className="new">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="">
              <Input
                ref={description}
                type="text"
                label="Output Description *"
                value={props.Name}
              />
            </div>
            <h6>{error}</h6>
            <Button handleClick={updateOutput} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
