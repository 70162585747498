import React from "react";

export default class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.data && this.props.data.length > 0 ? this.props.data[0] : "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data.length > 0) {
      this.setState({ value: this.props.data[0] });
    }
  }

  render() {
    return (
      <div className="select">
        <label htmlFor="input">{this.props.label}</label>
        <select
          onChange={(e) => {
            this.setState({ value: e.target.value });
            if (this.props.handleSelection) {
              this.props.handleSelection(e.target.value);
            }
          }}
          value={this.state.value || ""}
        >
          {this.props.data &&
            this.props.data.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      </div>
    );
  }
}
