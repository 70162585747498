import {
  faAddressBook,
  faEnvelope,
  faLocation,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function UserBox(props) {
  const [init, setInit] = useState("");
  useEffect(() => {
    const nm = props.item.Name.split(" ");
    if (nm.length > 1) {
      setInit(nm[0].substring(0, 1) + nm[1].substring(0, 1));
    } else setInit(nm[0].substring(0, 1));
  }, []);
  return (
    <div
      className={props.ID == props.item.ID ? "user-box active" : "user-box"}
      onClick={() => {
        props.setUserID(props?.item?.ID);
      }}
    >
      <div className="left">
        <h2 style={{ backgroundColor: props.item.ID ? "#fe5000" : "user-box" }}>
          {init}
        </h2>
      </div>
      <div className="right">
        <h3 className="title">{props?.item?.Name}</h3>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faPhone}
          />{" "}
          {props?.item?.Country}
        </p>
      </div>
    </div>
  );
}
