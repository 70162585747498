import { faTimes, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/outputs.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import IndicatorBox from "./IndicatorBox";
import SelectedIndicator from "./SelectedBeneficiary";
import SelectedOutput from "./SelectedOutput";
import OutputBox from "./OutputBox";

export default function Outputs() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [partnerName, setPartnerName] = useState();
  const [indicatorDetails, setIndicatorDetails] = useState();
  const selected = useRef();

  const jwt = require("jsonwebtoken");

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      const partner = decoded?.Partner;
      setPartnerName(partner);
    }
  }, [token]);

  useEffect(() => {
    if (partnerName) {
      setLoading(true);
      fetch(`/api/outputs/all/${partnerName}/${offset}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Failed to fetch data");
        })
        .then((data) => {
          console.log(data);
          setLoading(false);
          setData(data);
          if (data.data.length > 0) {
            setID(data.data[0].ID);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [partnerName, offset, refresh]);

  useEffect(() => {
    if (ID !== null) {
      setIndicatorDetails(ID);
    } else if (data?.data?.length > 0) {
      setIndicatorDetails(data?.data[0]);
    }
  }, [ID, data]);

  return (
    <div className="output">
      <div className="list">
        <div className="utp">
          <h3>{data?.data[0]?.Partner} Outputs</h3>
          <p
            onClick={() => {
              setClicked(true);
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faAdd} /> New Output
          </p>
        </div>

        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <OutputBox
                          key={index}
                          item={item}
                          ID={ID}
                          setUserID={setID}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  total={data?.total}
                  page={offset}
                  setOffset={setOffset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <h4>Output Details</h4>
                <hr />

                {indicatorDetails ? (
                  <SelectedOutput
                    setLoading={setLoading}
                    indicatorDetails={indicatorDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    ID={ID}
                    url="indicators"
                    partnerName={partnerName}
                  />
                ) : (
                  <p>Click on a user to see their details</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <OutputBox
                          key={index}
                          item={item}
                          ID={ID}
                          setUserID={setID}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  total={data?.total}
                  page={offset}
                  setOffset={setOffset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <h4>
                  <strong>Output Details</strong>
                </h4>

                <hr />

                {indicatorDetails ? (
                  <SelectedOutput
                    setLoading={setLoading}
                    indicatorDetails={indicatorDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    url="indicators"
                    partnerName={partnerName}
                  />
                ) : (
                  <p>Click on a user to see their details</p>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          partnerID={partnerName}
        />
      )}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [goals, setGoals] = useState([]);
  const [sos, setSos] = useState([]);
  const [ios, setIos] = useState([]);
  const [intermediateIDMap, setIntermediateIDMap] = useState({});
  const [selectedIntermediateID, setSelectedIntermediateID] = useState();
  const [goalIDMap, setGoalIDMap] = useState({});
  const [selectedGoalID, setSelectedGoalID] = useState();
  const [strategicIDMap, setStrategicIDMap] = useState({});
  const [selectedStrategicID, setSelectedStrategicID] = useState();

  const goal = useRef();
  const so = useRef();
  const io = useRef();
  const description = useRef();
  const intermediateID = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/goals`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        const goalNames = data?.data?.map((goal) => goal.Name);
        setGoals(goalNames);
        const goalMap = {};
        data?.data?.forEach((goal) => {
          goalMap[goal.Name] = goal.ID;
        });
        setGoalIDMap(goalMap);

        // Set the default selected goal to the first one in the list
        if (goalNames.length > 0) {
          setSelectedGoalID(goalMap[goalNames[0]]);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to fetch goals");
      });
  }, []);

  useEffect(() => {
    if (selectedGoalID) {
      setLoading(true);
      fetch(`/api/so/goal/${selectedGoalID}`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          const soNames = data?.data?.map((so) => so.Name);
          setSos(soNames);
          const strategicMap = {};
          data?.data?.forEach((so) => {
            strategicMap[so.Name] = so.ID;
          });
          setStrategicIDMap(strategicMap);

          // Set the default selected goal to the first one in the list
          if (soNames.length > 0) {
            setSelectedStrategicID(strategicMap[soNames[0]]);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("Failed to fetch sos");
        });
    }
  }, [selectedGoalID]);

  useEffect(() => {
    if (selectedStrategicID) {
      setLoading(true);
      fetch(`/api/io/so/${selectedStrategicID}`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          const ioNames = data?.data?.map((io) => io.Name);
          setIos(ioNames);

          const idMap = {};
          data?.data?.forEach((io) => {
            idMap[io.Name] = io.ID;
          });
          setIntermediateIDMap(idMap);

          // Set the default selected goal to the first one in the list
          if (ioNames.length > 0) {
            setSelectedIntermediateID(idMap[ioNames[0]]);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("Failed to fetch ios");
        });
    }
  }, [selectedStrategicID]);

  const handleIntermediateChange = (value) => {
    const selectedID = intermediateIDMap[value];
    setSelectedIntermediateID(selectedID);
  };

  const handleGoalChange = (value) => {
    const selectedgoal = goalIDMap[value];
    setSelectedGoalID(selectedgoal);
  };

  const handleStrategicChange = (value) => {
    const selectedso = strategicIDMap[value];
    setSelectedStrategicID(selectedso);
  };

  const createBeneficiary = () => {
    const body = {
      Partner: props.partnerID,
      Intermediate_ID: selectedIntermediateID,
      Description: description.current.getValue(),
    };

    setError("");
    setLoading(true);

    fetch(`/api/outputs/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to create ");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props.partnerID}: Define New Output</h3>
          <FontAwesomeIcon
            onClick={() => props.setClicked(false)}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <hr />
        <div className="new">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="">
              <Select
                ref={goal}
                data={goals}
                label="Select Goal *"
                handleSelection={handleGoalChange}
                value={goals[0]} // Set the default selected goal
              />
            </div>
            <div className="">
              <Select
                ref={so}
                data={sos}
                label="Select Strategic Outcome *"
                handleSelection={handleStrategicChange}
                value={sos[0]}
              />
            </div>
            <div className="">
              <Select
                ref={io}
                data={ios}
                label="Select Intermediate Outcome *"
                handleSelection={handleIntermediateChange}
              />
            </div>
            <div className="">
              <Input
                ref={description}
                type="text"
                label="Output Description *"
              />
            </div>
            <h6>{error}</h6>
            <Button handleClick={createBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
