import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";

export default function VCStats(props) {
  const [data, setData] = useState(null);
  const [charts, setCharts] = useState(null);
  const [stats, setStats] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();

    setAspect(width / height);
    setAspect1(width / (height * 0.8));
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    if (props.title === "Agrovets" || props.title === "AMF") {
      fetch(
        `/api/agrovets/monitoring/charts/${props.partner}/${props.start}/${props.end}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setCharts(data);
        })
        .catch((e) => {});
    } else if (props.title === "Farriers" || props.title === "FMF") {
      fetch(
        `/api/farriers/monitoring/charts/${props.partner}/${props.start}/${props.end}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setCharts(data);
        })
        .catch((e) => {});
    } else if (props.title === "Practitioners" || props.title === "AHMF") {
      fetch(
        `/api/practitioners/monitoring/charts/${props.partner}/${props.start}/${props.end}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setCharts(data);
        })
        .catch((e) => {});
    }
  }, [props.partner, props.start, props.end]);

  let tool;

  if (props.title === "AMF") {
    tool = "Agrovets";
  } else if (props.title === "AHMF") {
    tool = "Practitioners";
  } else if (props.title === "FMF") {
    tool = "Farriers";
  }

  // Replace "%20" and "&%20" in the final title
  tool = tool.replace("%20", " ").replace("&%20", " & ");

  return (
    <>
      {showing && (
        <div className="stats">
          <div className="top-monitoring">
            <TopItem
              title={`Total ${tool} Monitored`}
              amt={charts ? charts.Totals : 0}
              color="#C9EEFF"
            />
            <TopItem
              title="Legal Compliance"
              amt={charts ? charts.LegalComplicance : 0}
              color="#F1DEC9"
              link="/amf"
            />
            <TopItem
              title="Communicator"
              amt={charts ? charts.Communicator : 0}
              color="#FBFFB1"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Pharmacological Expert"
                  : props.title === "FMF"
                  ? "Farriery Expert"
                  : "Clinical Expert"
              }
              amt={charts ? charts.Pharmacological : 0}
              color="#FFA3FD"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Content of Agrovet"
                  : props.title === "FMF"
                  ? "Farrier Kit"
                  : "Kit Content"
              }
              amt={charts ? charts?.Content : 0}
              color="#C9F4AA"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Agrovet Governance"
                  : props.title === "FMF"
                  ? "Farrier Governance"
                  : "Clinical Governance"
              }
              amt={charts ? charts?.Governance : 0}
              color="#FFFBEB"
              link="/communityengagement/Careclubs"
            />
          </div>
          <div className="piesMonitoring">
            <div ref={p1ref} className="chart">
              <h3>Average Competency Performance</h3>
              {charts && (
                <CustomBarChart data={charts?.Competency} aspect={1.5} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(charts?.Competency);
                  }}
                />
              </div>
            </div>
            <div ref={p2ref} className="chart">
              <h3>AMF Results</h3>
              {charts && <CustomBarChart data={charts?.Results} aspect={1.5} />}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(charts?.Results);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TopItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      style={{ backgroundColor: props.color ?? props.color }}
      className="tp_item"
      // onClick={() => {
      //   window.location.href = props.link;
      // }}
    >
      <p>{props.title}</p>
      <h1>{withCommas(props.amt)}</h1>
    </div>
  );
};
