import React, { useEffect, useState } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Util/Input";
import "../../Styles/reports.scss";
import WaveLoading from "../Util/WaveLoading";
import IndicatorsSelect from "../Util/IndicatorsSelect";

export default function Tables(props) {
  const jwt = require("jsonwebtoken");
  const [selectedDate, setSelectedDate] = useState(2023);

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
  const decoded = jwt.decode(token);
  const partner = decoded.Partner;

  return (
    <div className="reports">
      <IPTT partner={partner} />
    </div>
  );
}

const IPTT = (props) => {
  const [goals, setGoals] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [active, setActive] = useState(year);
  const [quarter, setQuarter] = useState("Q1");

  const generateYearRange = () => {
    const currentYear = new Date().getFullYear();
    const myyears = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      myyears.push(String(i));
    }
    return myyears;
  };

  useEffect(() => {
    const today = new Date();
    setStart(`${today.getFullYear() - 1}-04-01`);
    setEnd(`${today.getFullYear()}-03-31`);
  }, []);

  useEffect(() => {
    const adjustedYear = parseInt(year) + 1;
    switch (active) {
      case "Q1":
        setStart(`${year}-04-01`);
        setEnd(`${year}-06-30`);
        setQuarter("Q1");
        break;
      case "Q2":
        setStart(`${year}-07-01`);
        setEnd(`${year}-09-30`);
        setQuarter("Q2");
        break;
      case "Q3":
        setStart(`${year}-10-01`);
        setEnd(`${year}-12-31`);
        setQuarter("Q3");
        break;
      case "Q4":
        setStart(`${adjustedYear}-01-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Q4");
        break;
      case year:
        setStart(`${year}-04-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Annual");
        break;
      default:
        break;
    }
  }, [active, year]);

  const handleYearChange = (v) => {
    setYear(v);
    setActive(v);
  };

  useEffect(() => {
    fetchGoals();
  }, [start, end]);

  const fetchGoals = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/goals`);
      const goalsData = await response.json();
      const enrichedGoals = await Promise.all(
        goalsData?.data?.map(async (goal) => {
          const sos = await fetchSOs(goal.ID);
          return { ...goal, sos };
        })
      );
      setGoals(enrichedGoals);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  };

  const fetchSOs = async (goalId) => {
    try {
      const response = await fetch(`/api/so/goal/${goalId}`);
      const sosData = await response.json();

      const enrichedSOs = await Promise.all(
        sosData?.data?.map(async (so) => {
          const ios = await fetchIOs(so.ID);
          return { ...so, ios };
        })
      );
      return enrichedSOs;
    } catch (error) {
      console.error("Error fetching SOs:", error);
      return [];
    }
  };

  const fetchIOs = async (soId) => {
    try {
      const response = await fetch(`/api/io/so/${soId}`);
      const iosData = await response.json();
      const enrichedIOs = await Promise.all(
        iosData?.data?.map(async (io) => {
          const outputs = await fetchOutputs(io.ID);
          return { ...io, outputs };
        })
      );
      return enrichedIOs;
    } catch (error) {
      console.error("Error fetching IOs:", error);
      return [];
    }
  };

  const fetchOutputs = async (ioId) => {
    try {
      const response = await fetch(`/api/outputs/io/${props?.partner}/${ioId}`);
      const outputsData = await response.json();
      const enrichedOutputs = await Promise.all(
        outputsData?.data?.map(async (output) => {
          const indicators = await fetchIndicators(output.ID);
          return { ...output, indicators };
        })
      );
      return enrichedOutputs;
    } catch (error) {
      console.error("Error fetching outputs:", error);
      return [];
    }
  };

  const fetchIndicators = async (outputId) => {
    try {
      const response = await fetch(`/api/indicators/myoutput/${outputId}`);
      const indicatorsData = await response.json();

      const enrichedIndicators = await Promise.all(
        indicatorsData?.data?.map(async (indicator) => {
          const performance = await fetchPerformance(indicator.ID);
          return { ...indicator, performance };
        })
      );
      return enrichedIndicators;
    } catch (error) {
      console.error("Error fetching indicators:", error);
      return [];
    }
  };

  const withCommas = (x) => {
    if (x === null) return 0;
    const p = x?.toString();
    if (p?.indexOf("$") !== -1 || p?.indexOf("%") !== -1) {
      return p?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      if (parseFloat(x) < 1) {
        let o = Math.round(parseFloat(x) * 10) / 10;
        return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      let o = Math.round(parseFloat(x));
      return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const RowItem = ({ item, type }) => {
    const [performanceData, setPerformanceData] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        try {
          const promises = item.indicators.map(async (indicator) => {
            const data = await fetchPerformance(indicator.ID);
            return { id: indicator.ID, data: data.data }; // Extract the first element of the data array
          });

          const results = await Promise.all(promises);
          console.log(results); // Log the results for debugging

          // Organize fetched data into an object for easier lookup
          console.log(results); // Log the results for debugging

          // Organize fetched data into an object for easier lookup
          const performanceDataMap = results.reduce((acc, result) => {
            result.data.forEach((performance) => {
              const date = new Date(performance.Date);
              const startYear = parseInt(start.substring(0, 4));
              const yearRef = startYear;

              // Determine the quarter based on the date
              let quarter;
              if (
                date >= new Date(yearRef, 3, 1) && // 01 April
                date <= new Date(yearRef, 5, 30) // 30 June
              ) {
                quarter = "Q1";
              } else if (
                date >= new Date(yearRef, 6, 1) && // 01 July
                date <= new Date(yearRef, 8, 30) // 30 September
              ) {
                quarter = "Q2";
              } else if (
                date >= new Date(yearRef, 9, 1) && // 01 October
                date <= new Date(yearRef, 11, 31) // 31 December
              ) {
                quarter = "Q3";
              } else if (
                date >= new Date(yearRef + 1, 0, 1) && // 01 January
                date <= new Date(yearRef + 1, 2, 31) // 31 March
              ) {
                quarter = "Q4";
              } else {
                return; // Skip if date is outside of the defined quarters
              }

              // Initialize the entry in the accumulator if it doesn't exist
              if (!acc[result.id]) {
                acc[result.id] = {
                  ID: result.id,
                  Description: performance.Description,
                  Target_Annual: performance.Target_Annual,
                  Target_Q1: performance.Target_Q1,
                  Target_Q2: performance.Target_Q2,
                  Target_Q3: performance.Target_Q3,
                  Target_Q4: performance.Target_Q4,
                  Achievement_Q1: 0,
                  Achievement_Q2: 0,
                  Achievement_Q3: 0,
                  Achievement_Q4: 0,
                  Annual_Achievement: 0,
                };
              }

              // Update the corresponding quarter achievement
              acc[result.id][`Achievement_${quarter}`] +=
                parseFloat(performance.Performance) || 0;
              acc[result.id].Annual_Achievement +=
                parseFloat(performance.Performance) || 0;
            });

            return acc;
          }, {});

          setPerformanceData(performanceDataMap);
        } catch (error) {
          console.error("Error fetching performance data:", error);
        }
      };

      fetchData();
    }, [item.indicators, start]); // Trigger fetch whenever item.indicators or start change

    return (
      <>
        {item?.indicators.map((indicator, index) => (
          <tr className={type} key={index}>
            {index === 0 && (
              <td rowSpan={item.indicators.length}>{item?.Description}</td>
            )}
            <td>{indicator?.Description}</td>
            <td className="cc">{indicator?.Baseline}</td>
            <td className="cc">{indicator?.Target_Annual}</td>
            <td className="cc">{indicator?.Target_Q1}</td>
            <td className="cc">{indicator?.Target_Q2}</td>
            <td className="cc">{indicator?.Target_Q3}</td>
            <td className="cc">{indicator?.Target_Q4}</td>
            {/* Render performance and achievement data */}

            <td className="cc">
              {performanceData[indicator.ID]?.Achievement_Q1}
            </td>
            <td className="cc">
              {performanceData[indicator.ID]?.Achievement_Q2}
            </td>
            <td className="cc">
              {performanceData[indicator.ID]?.Achievement_Q3}
            </td>
            <td className="cc">
              {performanceData[indicator.ID]?.Achievement_Q4}
            </td>
            <td className="cc">
              {parseInt(performanceData[indicator.ID]?.Achievement_Q1) +
                parseInt(performanceData[indicator.ID]?.Achievement_Q2) +
                parseInt(performanceData[indicator.ID]?.Achievement_Q3) +
                parseInt(performanceData[indicator.ID]?.Achievement_Q4)}
            </td>
            <td className="cc">{indicator?.VarianceExplanation}</td>
          </tr>
        ))}
      </>
    );
  };

  const fetchPerformance = async (indicatorId) => {
    try {
      const response = await fetch(
        `/api/performance/single/${indicatorId}/${start}/${end}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const textResponse = await response.text();
      console.log("API response:", textResponse);

      const performanceData = JSON.parse(textResponse);
      return performanceData; // Ensure this returns the correct structure for Performance and Achievements
    } catch (error) {
      console.error("Error fetching performance data:", error);
      return null;
    }
  };

  const renderHierarchy = (data) => {
    return data?.map((goal, index) => (
      <React.Fragment key={index}>
        <tr className="goal">
          <td colSpan="15">
            <span style={{ fontWeight: "bold" }}>{goal.Name}</span>
          </td>
        </tr>
        {goal.sos.map((so, soIndex) => (
          <React.Fragment key={`${index}-${soIndex}`}>
            <tr className="so">
              <td colSpan="15">
                <span style={{ fontWeight: "bold" }}>{so.Name}</span>
              </td>
            </tr>
            {so.ios.map((io, ioIndex) => (
              <React.Fragment key={`${index}-${soIndex}-${ioIndex}`}>
                <tr className="io">
                  <td colSpan="15">
                    <span style={{ fontWeight: "bold" }}>{io.Name}</span>
                  </td>
                </tr>
                {io.outputs.map((output, outputIndex) => (
                  <RowItem
                    key={`${index}-${soIndex}-${ioIndex}-${outputIndex}`}
                    item={output}
                    type="output"
                  />
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <div className="table">
      <div className="div1auto">
        <h1>Indicator Performance Tracking Table</h1>
        <div className="filter">
          <IndicatorsSelect
            handleSelection={handleYearChange}
            value={year}
            data={[String(new Date().getFullYear()), ...generateYearRange()]}
            label="Select Year"
          />

          <Input
            handleChange={(e) => {
              setStart(e.target.value);
            }}
            type="date"
            value={start}
            label="Start Date"
          />
          <Input
            handleChange={(e) => {
              setEnd(e.target.value);
            }}
            type="date"
            value={end}
            label="End Date"
          />
        </div>
      </div>
      <table className="cc">
        <thead>
          <tr>
            <th rowSpan="2">Indicator</th>
            <th rowSpan="2">Description</th>
            <th rowSpan="2">Baseline Value</th>

            <th colSpan="5">Quarterly Targets</th>
            <th colSpan="5">Achievements</th>
            <th rowSpan="2">Explanation of Variance</th>
          </tr>
          <tr>
            <th>Annual</th>
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>

            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <th>Annual</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="15">
                <WaveLoading />
              </td>
            </tr>
          ) : (
            renderHierarchy(goals)
          )}
        </tbody>
      </table>
      <button
        onClick={() =>
          props.handleExport(goals, "Indicator Performance Tracking Table")
        }
      >
        <FontAwesomeIcon icon={faDownload} /> Export
      </button>
    </div>
  );
};
