import "../../Styles/data.scss";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import GroupInput from "../Groups/GroupInput";
import { isMobile } from "react-device-detect";
import VCMap from "./VCMap";
import VCStats from "./VCStats";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EachSection(props) {
  const [data, setData] = useState(null);
  const [download, setDownload] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [head, setHead] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [paginatedMap, setPaginatedMap] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [editing, setEditing] = useState(null);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("Type");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);
  const [filtered, setFiltered] = useState(null);

  const jwt = require("jsonwebtoken");

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
  const decoded = jwt.decode(token);
  const partner = decoded.Partner;

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/${props.title.toLowerCase()}/data/${partner}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item !== "ObjectID" &&
              item !== "ID" &&
              item !== "Longitude" &&
              item !== "Latitude"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
          paginatedMap && setMapData(data);
        } else if (data?.result?.length > 0) {
          const cols = Object.keys(data.result[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [offset, props.refresh, paginatedMap, partner]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/${props.title.toLowerCase()}/data/${partner}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setDownload(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [partner]);

  //populates the map data

  useEffect(() => {
    if (!paginatedMap) {
      setIsLoading(true);
      fetch(`/api/${props.title.toLowerCase()}/data/${offset}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setMapData(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [offset, props.refresh, paginatedMap]);

  const exportToExcel = (filename, data) => {
    if (!Array.isArray(data) || data.length === 0) {
      console.error("Data should be a non-empty array");
      return;
    }

    const columnNames = Object.keys(data[0]); // Extract column names from the first object

    // Construct CSV content with column names as the first row
    let csv = columnNames.join(",") + "\n";
    csv += data
      .map((row) =>
        columnNames
          .map((col) => {
            let value = row[col];
            // If value contains comma, enclose it within double quotes
            if (typeof value === "string" && value.includes(",")) {
              value = `"${value}"`;
            }
            return value;
          })
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="vdata">
      <div className="vtitle">
        <h3>{`Section: ${props.title}`}</h3>
        {partner !== "Brooke East Africa" ? (
          <h4>{`Partner: ${partner}`}</h4>
        ) : (
          ""
        )}
      </div>
      <hr />
      <div className="titles">
        <h3>{`Stats for ${props.title}`}</h3>
      </div>
      <div className="vstats">
        <VCStats
          title={props.title
            .replace(" ", "")
            .replace("(", "")
            .replace(")", "")
            .replace("  & ", "")
            .replace(" ", "")}
          category={props.title}
          setRefresh={props.setRefresh}
          refresh={props.refresh}
          setValue={props.setValue}
          value={props.value}
          partner={partner}
        />
      </div>
      <hr />
      {/* <div className="maptitles">
        <h3>{`Map for ${props.title}`}</h3>
        <i onClick={() => setPaginatedMap(!paginatedMap)}>
          {paginatedMap
            ? `Show all ${props.title}`
            : `Show listed ${props.title}`}
        </i>
      </div> */}
      {/* <div className="vmap">
        {data && data?.data?.length > 0 && (
          <VCMap
            url={props.dataURL}
            data={mapData?.data}
            title={props.title}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            setValue={props.setValue}
            value={props.value}
          />
        )}
      </div> */}
      {/* <hr /> */}
      <div className="titles">
        <h3>{`${props.title} Data for ${partner}`}</h3>
        <div className="filters">
          <FontAwesomeIcon
            onClick={() => {
              exportToExcel(`${props.title} Data`, download.data);
            }}
            className="download"
            icon={faDownload}
          />
        </div>
        <div className="filter">
          <div
            onClick={() => {
              if (!filter) {
                setFilter(true);
                setFColumn("Type");
                setFOperator("=");
              } else {
                setFilter(false);
                setFColumn(null);
                setFOperator(null);
                setFValue(null);
              }
            }}
            className="add"
          >
            <i className="fa fa-plus-circle"></i>
            <p>{filter ? "Close Filter" : "Add Filter"}</p>
          </div>
          {filter && (
            <div className="math">
              <select
                onChange={(e) => {
                  setFColumn(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="Type">Country</option>
                <option value="Status">Name</option>
                <option value="createdAt">Partner</option>
              </select>
              <select
                onChange={(e) => {
                  setFOperator(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="=">Equal To</option>
                <option value=">">Greater Than</option>
                <option value="<">Less Than</option>
              </select>
              <input
                onChange={(e) => {
                  setFValue(e.target.value);
                }}
                type="text"
                name=""
                id=""
                placeholder="Value"
                required
              />
              <button
                onClick={() => {
                  setFilter(false);
                }}
              >
                Add
              </button>

              {filtered && (
                <FontAwesomeIcon
                  onClick={() => {
                    exportToExcel(`${props.title} Data`, download.data);
                  }}
                  className="download"
                  icon={faDownload}
                />
              )}
            </div>
          )}
          {fcolumn && foperator && fvalue && (
            <div className="filter">
              <p>{fcolumn}</p>
              <h6>{foperator}</h6>
              <p>{fvalue}</p>
              <i
                onClick={() => {
                  setFColumn(null);
                  setFOperator(null);
                  setFValue(null);
                }}
                className="fa fa-times"
              ></i>
            </div>
          )}
        </div>
      </div>
      <div className="list">
        <div
          style={{
            gridTemplateColumns: `repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data && data?.data?.length > 0
          ? data?.data?.map((item, i) => {
              return (
                <Item
                  setShowing={setShowing}
                  key={i}
                  index={i}
                  data={item}
                  head={head}
                  count={count}
                />
              );
            })
          : data?.result?.length > 0 &&
            data?.result?.map((item, i) => {
              return (
                <Item
                  setShowing={setShowing}
                  key={i}
                  index={i}
                  data={item}
                  head={head}
                  count={count}
                />
              );
            })}
        <div className="btns">
          {data?.total && (
            <Pagination
              total={data?.total}
              setOffset={setOffset}
              page={offset}
            />
          )}
        </div>
        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState("Basic");

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);
    if (active === "Basic") {
      if (props.showing !== null) {
        const cols = Object.keys(props.data[props.showing]);
        let d = [];
        cols.forEach((item) => {
          if (item.toLowerCase() !== "geom") {
            d.push(item);
          }
        });
        setCols(d);
      }
      setIsLoading(false);
    } else {
      let d = "farmeraddress";
      switch (active) {
        case "Address":
          d = "farmeraddress";
          break;
        case "Farm":
          d = "farmerresources";
          break;
        case "Groups":
          d = "farmergroups";
          break;
        case "Value Chains":
          d = "farmervaluechains";
          break;
        default:
          setActive("Basic");
          break;
      }
      fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.length > 0) {
            setData(data);
            const cols = Object.keys(data[0]);
            let d = [];
            cols.forEach((item) => {
              if (item.toLowerCase() !== "geom") {
                d.push(item);
              }
            });
            setCls(d);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="bar">
          <Bar txt="Details" />
        </div>
        <div className="content">
          {cols &&
            cols.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {props.data[props.showing][item]}
                </p>
              );
            })}
          {cls &&
            cls.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {data[index][item]}
                </p>
              );
            })}
          {isLoading && <WaveLoading />}
          <div className="tally">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return (
                  <p
                    className={i === index ? "active" : ""}
                    onClick={() => {
                      setIndex(i);
                    }}
                    key={i}
                  >
                    {i + 1}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddMember = (props) => {
  const [searchFarmers, setsearchFarmers] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isError, setIsError] = useState(null);

  const [body, setBody] = useState(null);

  useEffect(() => {
    props.selected &&
      setBody({
        FarmerID: props.selected.NationalID,
        Name: props.gname,
        Type: props.gtype,
      });
  }, [props.selected]);

  const List = (params) => {
    return (
      <div
        onClick={() => {
          props.setSelected(params.item);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  function searchFarmer(gname, q) {
    fetch(`/api/farmerdetails/seachbynid/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setsearchFarmers(data);
      })
      .catch((e) => {});
  }

  function addFarmerToGroup() {
    fetch(`/api/farmergroups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setSuccess(data.success);
        setIsError(data.error);
      })
      .catch((e) => {});
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setToggleAddMember(false);
              props.setSelected(null);
            }}
            className="fa fa-times"
          ></i>
        </div>

        {props.selected ? (
          <div className="confirm">
            {success && <p className="success">{success}</p>}
            {isError && <p className="isError">{isError}</p>}
            <p>
              Confirm to add {props.selected.Name} to{" "}
              {props.gname.replaceAll("%20", " ")}?
            </p>
            <i
              className="newMember"
              onClick={() => {
                if (success || isError) {
                  props.setToggleAddMember(false);
                  setSuccess(null);
                  setIsError(null);
                } else {
                  addFarmerToGroup();
                }
              }}
            >
              Okay
            </i>
          </div>
        ) : (
          <div className="search">
            <p>Add a member to {props.gname.replaceAll("%20", " ")}</p>
            <GroupInput
              type="number"
              handleChange={(e) => {
                searchFarmer(props.gname, e);
              }}
              label="Search ID Number"
              hint="Type to search"
            />
            <div className="search_list">
              {searchFarmers?.map((item, i) => {
                return (
                  <List key={i} item={item} setSelected={props.setSelected} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};
