import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import BarGraphExpense from "../Stats/BarGraphExpense";
import BubbleChartMonth from "../Stats/BubbleChartMonths";
import CustomPieChart from "../Stats/CustomPieChart";
import CustomBarChart from "../Stats/CustomBarChart";
import StackedAreaChart from "../Stats/StackedAreaChart";
import HorizontalChart from "../Stats/HorizontalChart";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";

export default function VCStats(props) {
  const [data, setData] = useState(null);
  const [charts, setCharts] = useState(null);
  const [stats, setStats] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();

    setAspect(width / height);
    setAspect1(width / (height * 0.8));
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    fetch(`/api/data/all/stats/${props.title}/${props.partner}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/${props.title}/charts/${props.partner}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCharts(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      {showing && (
        <div className="stats">
          <div className="top">
            <TopItem
              title={`Total ${props.title}`}
              amt={stats ? stats.Category : 0}
              color="#fe5000"
            />
            <TopItem
              title={`Partners working with ${props.title}`}
              amt={stats ? stats.Partners : 0}
              color="#fe5000"
            />
          </div>
          <div className="piesMonitoring">
            <div ref={p1ref} className="chart">
              <h3>Distribution by Country</h3>
              {charts && <CustomBarChart data={charts?.Country} aspect={2} />}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(charts?.Country);
                  }}
                />
              </div>
            </div>
            <div ref={p2ref} className="chart">
              <h3>Distribution by Partner</h3>
              {charts && <CustomBarChart data={charts?.Partner} aspect={2} />}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(charts?.Partner);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TopItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      style={{ backgroundColor: props.color ?? props.color }}
      className="tp_item"
    >
      <p>{props.title}</p>
      <h1>{withCommas(props.amt)}</h1>
    </div>
  );
};
