import React from "react";

export default function DiagonalRectangle(props) {
  return (
    <div
      className="diagonal-rectangle"
      onClick={() => {
        props.handleClick();
      }}
    >
      <p>{props.label}</p>
    </div>
  );
}
