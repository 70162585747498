import { useEffect, useState } from "react";
import logo from "../../assets/imgs/logo.png";
import jwt from "jsonwebtoken"; // Ensure you have the correct import for jwt

export default function Navigation2(props) {
  const pathname = window.location.pathname.split("/");
  const [role, setRole] = useState();

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      const partner = decoded?.Role;
      setRole(partner);
    }
  }, [token]);

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.clear();
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            setShowing(true);
          }}
          className={params.link === params.active ? "active-item" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
        {props.showing && (
          <>
            <h3>BEA Data Portal</h3>
          </>
        )}
        <hr />
      </div>

      <Item
        url="/"
        txt="Home"
        active={pathname[1]}
        link=""
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="AMF Analysis"
        url="/AMF"
        active={pathname[1]}
        link="AMF"
        icon="fa-tasks"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="FMF Analysis"
        url="/FMF"
        active={pathname[1]}
        link="FMF"
        icon="fa-folder"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="AHMF Analysis"
        url="/AHMF"
        active={pathname[1]}
        link="AHMF"
        icon="fa-hdd"
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="GIS Maps"
        url="/gis"
        icon="fa-map"
        active={pathname[1]}
        link="gis"
        options={[]}
        showing={props.showing}
      />

      <Item
        url="/indicators"
        txt="MEL Section"
        active={pathname[1]}
        link="indicators"
        icon="fas fa-newspaper"
        options={[]}
        showing={props.showing}
      />

      {role === "Admin" && (
        <Item
          txt="BEA Partners"
          url="/partners"
          active={pathname[1]}
          link="partners"
          icon="fa-user"
          options={[]}
          showing={props.showing}
        />
      )}

      {role === "Admin" && (
        <Item
          txt="Users Section"
          url="/users"
          active={pathname[1]}
          link="users"
          icon="fa-user"
          options={[]}
          showing={props.showing}
        />
      )}

      <Item
        txt="Account Details"
        url="/settings"
        active={pathname[1]}
        link="settings"
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
