import { useEffect, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import GaugeChart from "react-gauge-chart";
import { CiCalendarDate } from "react-icons/ci";
import "../../Styles/stats.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import QuarterlyBarChart from "../Stats/QuarterlyBarChart";
import Input from "../Util/Input";
import Select from "../Util/Select";
import IndicatorsSelect from "../Util/IndicatorsSelect";

export default function SingleView(props) {
  const [data, setData] = useState(null);
  const [component, setComponent] = useState();
  const [indicator, setIndicator] = useState();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [indicators, setIndicators] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(year);
  const [quarter, setQuarter] = useState("Q1");
  const [activeF, setActiveF] = useState(year);

  const code = window.location.pathname.split("/").pop();

  // Function to generate year range
  const generateYearRange = () => {
    const currentYear = new Date().getFullYear();
    const myyears = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      myyears.push(String(i));
    }
    return myyears;
  };

  useEffect(() => {
    const adjustedYear = parseInt(year) + 1;
    switch (active) {
      case "Q1":
        setStart(`${year}-04-01`);
        setEnd(`${year}-06-30`);
        setQuarter("Q1");
        break;
      case "Q2":
        setStart(`${year}-07-01`);
        setEnd(`${year}-09-30`);
        setQuarter("Q2");
        break;
      case "Q3":
        setStart(`${year}-10-01`);
        setEnd(`${year}-12-31`);
        setQuarter("Q3");
        break;
      case "Q4":
        setStart(`${adjustedYear}-01-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Q4");
        break;
      case year:
        setStart(`${year}-04-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Annual");
        break;
      default:
        break;
    }
  }, [active, year]);

  useEffect(() => {
    if (code) {
      setLoading(true);
      fetch(`/api/indicators/${code}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setData(data[0]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [code]);

  const handleYearChange = (v) => {
    setYear(v);
    setActive(v);
    // Call any additional functions to update places here
  };

  return (
    <div className="stats">
      <div className="div2equal">
        <h3>{data?.Description}</h3>
        <div className="div1auto">
          <div className="filter">
            <IndicatorsSelect
              handleSelection={handleYearChange}
              value={year}
              data={[String(new Date().getFullYear()), ...generateYearRange()]}
              label="Select Year"
            />

            <Input
              handleChange={(e) => {
                setStart(e.target.value);
              }}
              type="date"
              value={start}
              label="Start Date"
            />
            <Input
              handleChange={(e) => {
                setEnd(e.target.value);
              }}
              type="date"
              value={end}
              label="End Date"
            />
          </div>
        </div>
      </div>

      <Top
        code={code}
        indicator={indicator}
        component={component}
        year={year}
        data={data}
        active={active}
      />
    </div>
  );
}

const Top = (props) => {
  const [isActive, setIsActive] = useState([true, false, false, false, false]);
  const [data, setData] = useState(null);

  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);

  const [selectedQuarter, setSelectedQuarter] = useState("Q1"); // Default to Q1

  const [targetQ1, setTargetQ1] = useState(0);
  const [targetQ2, setTargetQ2] = useState(0);
  const [targetQ3, setTargetQ3] = useState(0);
  const [targetQ4, setTargetQ4] = useState(0);
  const [targetAnnual, setTargetAnnual] = useState(0);

  useEffect(() => {
    const updatedActiveDivs = isActive.map(
      (val, index) => index === props?.active - 2023
    );
    setIsActive(updatedActiveDivs);
  }, [props?.active]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (props?.code) {
      getData(props?.start, props?.end, setData);
    }
  }, [props?.code]);

  useEffect(() => {
    if (props?.start && props?.end) {
      getData(props.start, props.end, setData);
    }
  }, [props?.code, props?.start, props?.end]);

  useEffect(() => {
    getData(`${props?.active}-04-01`, `${props?.active}-06-30`, setQ1);
    getData(`${props?.active}-07-01`, `${props?.active}-09-30`, setQ2);
    getData(`${props?.active}-10-01`, `${props?.active}-12-31`, setQ3);
    getData(
      `${parseInt(props?.active) + 1}-01-01`,
      `${parseInt(props?.active) + 1}-03-31`,
      setQ4
    );
  }, [props?.active, props?.code, data]);

  function getData(st, en, setMyData) {
    fetch(`/api/performance/single/${props?.code}/${st}/${en}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to fetch data");
      })
      .then((responseData) => {
        let performance = 0;
        let targets = {
          Target_Q1: 0,
          Target_Q2: 0,
          Target_Q3: 0,
          Target_Q4: 0,
          Target_Annual: 0,
        };

        // Aggregate performance based on the Date field
        responseData.data.forEach((item) => {
          performance += parseInt(item.Performance);
          targets.Target_Q1 += parseInt(item.Target_Q1 || 0);
          targets.Target_Q2 += parseInt(item.Target_Q2 || 0);
          targets.Target_Q3 += parseInt(item.Target_Q3 || 0);
          targets.Target_Q4 += parseInt(item.Target_Q4 || 0);
          targets.Target_Annual += parseInt(item.Target_Annual || 0);
        });

        // Update the state with the aggregated performance and targets
        setMyData(performance);
        setTargetQ1(targets.Target_Q1);
        setTargetQ2(targets.Target_Q2);
        setTargetQ3(targets.Target_Q3);
        setTargetQ4(targets.Target_Q4);
        setTargetAnnual(targets.Target_Annual);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  // Event handler for clicking on a bar in the bar chart
  const handleBarClick = (quarter) => {
    setSelectedQuarter(quarter);
  };

  return (
    <>
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2> {props.indicator?.Description} </h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <p>{props?.data?.Goal}</p>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <p>{props?.data?.SO}</p>
                </div>
              </div>

              <div className="section">
                <div className="single">
                  <p>{props?.data?.IO}</p>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <p>{props?.data?.Output}</p>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <p>{props?.data?.Description}</p>
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Yearly Distribution</h3>
              <QuarterlyBarChart
                data={[
                  { name: "Q1", value: q1 },
                  { name: "Q2", value: q2 },
                  { name: "Q3", value: q3 },
                  { name: "Q4", value: q4 },
                ]}
                aspect={1.2}
                color="#BA0C2F"
                onBarClick={handleBarClick} // Pass the event handler
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance Vs Target</h2>
          </div>
          <div className="outer">
            <div className="section">
              <h3>
                {props?.active} {selectedQuarter} Performance Vs {props?.active}{" "}
                {selectedQuarter} Target
              </h3>
              <GaugeChart
                className="gg"
                id="gauge-chart1"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  selectedQuarter === "Q1"
                    ? targetQ1 === 0
                      ? 0
                      : q1 / targetQ1
                    : selectedQuarter === "Q2"
                    ? targetQ2 === 0
                      ? 0
                      : q2 / targetQ2
                    : selectedQuarter === "Q3"
                    ? targetQ3 === 0
                      ? 0
                      : q3 / targetQ3
                    : targetQ4 === 0
                    ? 0
                    : q4 / targetQ4
                }
                textColor="gray"
              />

              <div className="">
                <p className="bold">
                  Performance:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? q1
                      : selectedQuarter === "Q2"
                      ? q2
                      : selectedQuarter === "Q3"
                      ? q3
                      : q4
                  )}
                </p>
                <p className="bold">
                  Target:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? targetQ1
                      : selectedQuarter === "Q2"
                      ? targetQ2
                      : selectedQuarter === "Q3"
                      ? targetQ3
                      : targetQ4
                  )}
                </p>

                {data &&
                  (selectedQuarter === "Q1" && q1 / targetQ1 > 1 ? (
                    <p className="bolds">
                      Actual Performance: {((q1 / targetQ1) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q2" && q2 / targetQ2 > 1 ? (
                    <p className="bolds">
                      Actual Performance: {((q2 / targetQ2) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q3" && q3 / targetQ3 > 1 ? (
                    <p className="bolds">
                      Actual Performance: {((q3 / targetQ3) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q4" && q4 / targetQ4 > 1 ? (
                    <p className="bolds">
                      Actual Performance: {((q4 / targetQ4) * 100).toFixed(2)} %
                    </p>
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
            <div className="section">
              <h3>
                {props?.active} {selectedQuarter} Performance Vs Annual Target
              </h3>
              <GaugeChart
                className="gg"
                id="gauge-chart2"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  targetAnnual === 0
                    ? 0
                    : selectedQuarter === "Q1"
                    ? q1 / targetAnnual
                    : selectedQuarter === "Q2"
                    ? q2 / targetAnnual
                    : selectedQuarter === "Q3"
                    ? q3 / targetAnnual
                    : q4 / targetAnnual
                }
                textColor="gray"
              />

              <div className="">
                <p className="bold">
                  Performance:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? q1
                      : selectedQuarter === "Q2"
                      ? q2
                      : selectedQuarter === "Q3"
                      ? q3
                      : q4
                  )}
                </p>
                <p className="bold">Target: {withCommas(targetAnnual)}</p>

                {data &&
                  (selectedQuarter === "Q1" && q1 / targetAnnual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q1 / targetAnnual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q2" && q2 / targetAnnual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q2 / targetAnnual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q3" && q3 / targetAnnual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q3 / targetAnnual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q4" && q4 / targetAnnual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q4 / targetAnnual) * 100).toFixed(2)} %
                    </p>
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
