import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation2";
import { useState } from "react";
import Stats from "../components/Stats/Stats";
import { useEffect } from "react";
import PortalUsers from "../components/Users/PortalUsers";
import NewPortalUser from "../components/Users/NewPortalUser";
import Framework from "../components/Frameworks/Framework";
import Settings from "../components/Settings/Settings";
import Sections from "../components/ValueChains/Sections";
import MonitoringSection from "../components/ValueChains/MonitoringSection";
import HomeSections from "../components/Home/HomeSections";
import AMFSections from "../components/Monitoring/Agrovets/LegalCompliance/AMFSection";
import PartnersHome from "../components/Partners/PartnersHome";
import GISNew from "../components/GIS/GISNew";
import MapTemplate from "../components/GIS/MapTemplate";
import Mel from "../components/IndicatorsSection/Mel";
import Performance from "../components/Performance/Performance";
import SingleView from "../components/Performance/SingleView";
import IPTT from "../components/Performance/IPTT";
import Tables from "../components/Performance/Tables";
import LandingNew from "./LandingNew";
import UsersHome from "../components/Users/UsersHome";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const pathname = window.location.pathname.split("/");
  const workspace = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <LandingNew />}

            {pathname[1] === "AMF" && pathname.length === 2 && (
              <Framework showing={showing} />
            )}

            {pathname[1] === "FMF" && pathname.length === 2 && (
              <Framework showing={showing} />
            )}

            {pathname[1] === "AHMF" && pathname.length === 2 && (
              <Framework showing={showing} />
            )}

            {pathname[1] === "gis" && pathname.length === 2 && (
              <GISNew showing={showing} />
            )}

            {pathname[1] === "gis" && pathname[2] === "export" && (
              <MapTemplate showing={showing} path={pathname[2]} />
            )}

            {pathname[1] === "settings" && pathname.length === 2 && (
              <Settings showing={showing} />
            )}

            {pathname[1] === "animalhealth" && (
              <Sections vc={`${pathname[2]}`} />
            )}
            {pathname[1] === "advocacy" && <Sections vc={`${pathname[2]}`} />}
            {pathname[1] === "communityengagement" && (
              <Sections vc={`${pathname[2]}`} />
            )}

            {pathname[1] === "partners" && <PartnersHome />}

            {pathname[1] === "users" && pathname.length === 2 && <UsersHome />}

            {pathname[1] === "indicators" && <Mel />}

            {pathname[1] === "indicators" && pathname[2] === "Performance" && (
              <Performance />
            )}
            {pathname[1] === "indicators" && pathname[2] === "singleview" && (
              <SingleView />
            )}

            {pathname[1] === "indicators" && pathname[2] === "MEL" && (
              <Tables />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
