import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/imgs/logo.png";

export default function Header(props) {
  var jwt = require("jsonwebtoken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [showSettings, setShowSettings] = useState(false);

  const [toggleAccount, setToggleAccount] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [clicked, setClicked] = useState(false);

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);

        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch((error) => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = "/login";
      });
  };

  return (
    <div>
      <div className="header">
        <div className="left">
          <i
            className="fa fa-bars"
            onClick={() => {
              props.setShowing(!props.showing);
            }}
          ></i>{" "}
          <p>{currentUser.Name}</p>
          {/* <i className="fa fa-user"></i> */}
        </div>

        <div className="right">
          <p>Logout</p>
          <i className="fa fa-sign-out-alt" onClick={logout}></i>
        </div>
      </div>
    </div>
  );
}
