import { faTimes, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/indicators.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import IndicatorBox from "./IndicatorBox";
import SelectedIndicator from "./SelectedIndicator";
import GaugeChart from "react-gauge-chart";
import QuarterlyBarChart from "../Stats/QuarterlyBarChart";
import PaginationIndicators from "../Util/PaginationIndicators";
import IndicatorsSelect from "../Util/IndicatorsSelect";
import WaveLoading from "../Util/WaveLoading";

export default function Indicators(props) {
  const [offset, setOffset] = useState(0);
  const { indicators } = require("../../assets/data/data");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [indicatorDetails, setIndicatorDetails] = useState();
  const selected = useRef();
  const [partnerName, setPartnerName] = useState();

  const [error, setError] = useState("");

  const [goals, setGoals] = useState([]);
  const [sos, setSos] = useState([]);
  const [ios, setIos] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [intermediateIDMap, setIntermediateIDMap] = useState({});
  const [selectedIntermediateID, setSelectedIntermediateID] = useState();
  const [goalIDMap, setGoalIDMap] = useState({});
  const [selectedGoalID, setSelectedGoalID] = useState();
  const [strategicIDMap, setStrategicIDMap] = useState({});
  const [selectedStrategicID, setSelectedStrategicID] = useState();
  const [outputPartNameMap, setOutputPartNameMap] = useState({});
  const [selectedOutputPartName, setSelectedOutputPartName] = useState();
  const [outputIDMap, setOutputIDMap] = useState({});
  const [selectedOutputID, setSelectedOutputID] = useState();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [active, setActive] = useState(year);
  const [quarter, setQuarter] = useState("Q1");

  const generateYearRange = () => {
    const currentYear = new Date().getFullYear();
    const myyears = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      myyears.push(String(i));
    }
    return myyears;
  };

  const goal = useRef();
  const so = useRef();
  const io = useRef();
  const output = useRef();

  const jwt = require("jsonwebtoken");

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  const handleIntermediateChange = (value) => {
    const selectedID = intermediateIDMap[value];
    setSelectedIntermediateID(selectedID);
  };

  const handleGoalChange = (value) => {
    const selectedgoal = goalIDMap[value];
    setSelectedGoalID(selectedgoal);
  };

  const handleStrategicChange = (value) => {
    const selectedso = strategicIDMap[value];
    setSelectedStrategicID(selectedso);
  };

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);

      const partner = decoded?.Partner;
      setPartnerName(partner);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/goals`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        const goalNames = data?.data?.map((goal) => goal.Name);
        setGoals(goalNames);
        const goalMap = {};
        data?.data?.forEach((goal) => {
          goalMap[goal.Name] = goal.ID;
        });
        setGoalIDMap(goalMap);

        // Set the default selected goal to the first one in the list
        if (goalNames.length > 0) {
          setSelectedGoalID(goalMap[goalNames[0]]);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to fetch goals");
      });
  }, []);

  useEffect(() => {
    if (selectedGoalID) {
      setLoading(true);
      fetch(`/api/so/goal/${selectedGoalID}`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          const soNames = data?.data?.map((so) => so.Name);
          setSos(soNames);
          const strategicMap = {};
          data?.data?.forEach((so) => {
            strategicMap[so.Name] = so.ID;
          });
          setStrategicIDMap(strategicMap);

          // Set the default selected goal to the first one in the list
          if (soNames.length > 0) {
            setSelectedStrategicID(strategicMap[soNames[0]]);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("Failed to fetch sos");
        });
    }
  }, [selectedGoalID]);

  useEffect(() => {
    if (selectedStrategicID) {
      setLoading(true);
      fetch(`/api/io/so/${selectedStrategicID}`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          const ioNames = data?.data?.map((io) => io.Name);
          setIos(ioNames);

          const idMap = {};
          data?.data?.forEach((io) => {
            idMap[io.Name] = io.ID;
          });
          setIntermediateIDMap(idMap);

          // Set the default selected goal to the first one in the list
          if (ioNames.length > 0) {
            setSelectedIntermediateID(idMap[ioNames[0]]);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("Failed to fetch ios");
        });
    }
  }, [selectedStrategicID]);

  useEffect(() => {
    if (selectedIntermediateID) {
      setLoading(true);
      fetch(`/api/outputs/io/${partnerName}/${selectedIntermediateID}`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          const outNames = data?.data?.map((out) => out.Description);
          const ptNames = data?.data?.map((out) => out.Description);
          setOutputs(outNames);
          const outputMap = {};
          const partMap = {};
          data?.data?.forEach((out) => {
            outputMap[out.Description] = out.ID;
            partMap[out.Description] = out.Partner;
          });
          setOutputIDMap(outputMap);

          setOutputPartNameMap(partMap);

          // Set the default selected goal to the first one in the list
          if (outNames.length > 0) {
            setSelectedOutputID(outputMap[outNames[0]]);
          }

          // Set the default selected goal to the first one in the list
          if (ptNames.length > 0) {
            setSelectedOutputPartName(partMap[ptNames[0]]);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("Failed to fetch ios");
        });
    }
  }, [selectedIntermediateID, partnerName, selectedIntermediateID]);

  useEffect(() => {
    if (selectedOutputID) {
      setLoading(true);
      fetch(`/api/indicators/output/${selectedOutputID}/${offset}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
          if (data?.data?.length > 0) {
            setID(data?.data[0]?.ID);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [selectedOutputID, offset, refresh]);

  const handleOutputChange = (value) => {
    const selectedOutput = outputIDMap[value];
    setSelectedOutputID(selectedOutput);
    const partName = outputPartNameMap[value];
    setSelectedOutputPartName(partName);
  };

  useEffect(() => {
    if (ID !== null) {
      setIndicatorDetails(ID);
    } else setIndicatorDetails(data?.data[0]);
  }, [ID]);

  useEffect(() => {
    const adjustedYear = parseInt(year) + 1;
    switch (active) {
      case "Q1":
        setStart(`${year}-04-01`);
        setEnd(`${year}-06-30`);
        setQuarter("Q1");
        break;
      case "Q2":
        setStart(`${year}-07-01`);
        setEnd(`${year}-09-30`);
        setQuarter("Q2");
        break;
      case "Q3":
        setStart(`${year}-10-01`);
        setEnd(`${year}-12-31`);
        setQuarter("Q3");
        break;
      case "Q4":
        setStart(`${adjustedYear}-01-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Q4");
        break;
      case year:
        setStart(`${year}-04-01`);
        setEnd(`${adjustedYear}-03-31`);
        setQuarter("Annual");
        break;
      default:
        break;
    }
  }, [active, year]);

  console.log(outputs.length);

  const handleYearChange = (v) => {
    setYear(v);
    setActive(v);
  };

  return (
    <div className="beneficiary">
      <div className="selection">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid-container">
            <div className="">
              <Select
                ref={goal}
                data={goals}
                label="Goal *"
                handleSelection={handleGoalChange}
                value={goals[0]}
              />
            </div>
            <div className="">
              <Select
                ref={so}
                data={sos}
                label="Strategic Outcome *"
                handleSelection={handleStrategicChange}
                value={sos[0]}
              />
            </div>
            <div className="">
              <Select
                ref={io}
                data={ios}
                label="Intermediate Outcome *"
                handleSelection={handleIntermediateChange}
                value={ios[0]}
              />
            </div>
            <div className="">
              <Select
                ref={output}
                data={outputs}
                label="Output*"
                handleSelection={handleOutputChange}
              />
            </div>
          </div>
        </form>
        {loading && <Loading />}
      </div>

      {partnerName !== "Brooke East Africa" && (
        <div className="newIndic">
          <h3>{data?.data[0]?.Partner} </h3>
          {outputs.length != 0 && (
            <h2
              onClick={() => {
                setClicked(true);
              }}
            >
              <FontAwesomeIcon className="fa-add" icon={faAdd} /> New Indicator
            </h2>
          )}
        </div>
      )}

      <hr />
      <div className="list">
        <div className="utp">
          <h3>
            <span style={{ fontWeight: "bold", color: "black" }}>
              Indicators of:
            </span>
            {selectedOutputPartName}
          </h3>

          <div className="filter">
            <IndicatorsSelect
              handleSelection={handleYearChange}
              value={year}
              data={[String(new Date().getFullYear()), ...generateYearRange()]}
              label="Select Year"
            />

            <Input
              handleChange={(e) => {
                setStart(e.target.value);
              }}
              type="date"
              value={start}
              label="Start Date"
            />
            <Input
              handleChange={(e) => {
                setEnd(e.target.value);
              }}
              type="date"
              value={end}
              label="End Date"
            />
          </div>
        </div>

        <hr />
        <div className="divs">
          {isMobile ? (
            <div className="div31">
              <div>
                <div className="lcontainer">
                  <div className="ind-list">
                    {data &&
                      data?.data?.length > 0 &&
                      data?.data?.map((item, index) => {
                        console.log(item);
                        return (
                          <IndicatorBox
                            key={index}
                            item={item}
                            ID={ID}
                            setUserID={setID}
                          />
                        );
                      })}
                  </div>
                </div>
                {data && (
                  <Pagination
                    total={data?.total}
                    page={offset}
                    setOffset={setOffset}
                    onPageChange={(v) => {
                      setOffset(v);
                    }}
                  />
                )}
              </div>

              <div ref={selected}>
                <div className="selected">
                  <h4>Indicator Details</h4>
                  <hr />

                  {indicatorDetails ? (
                    indicatorDetails && (
                      <SelectedIndicator
                        setLoading={setLoading}
                        indicatorDetails={indicatorDetails}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        url="indicators"
                        partnerName={partnerName}
                      />
                    )
                  ) : (
                    <>
                      <p>Click on a user to see their details</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="div31">
              <div>
                <div className="bar">
                  <div></div>
                  <h2> Update Indicator Performance </h2>
                </div>

                <div className="lcontainer">
                  <div className="ind-list">
                    {data &&
                      data?.data?.length > 0 &&
                      data?.data?.map((item, index) => {
                        return (
                          <IndicatorBox
                            key={index}
                            item={item}
                            ID={ID}
                            setUserID={setID}
                          />
                        );
                      })}
                  </div>
                </div>
                {data && (
                  <PaginationIndicators
                    total={data?.total}
                    page={offset}
                    setOffset={setOffset}
                    onPageChange={(v) => {
                      setOffset(v);
                    }}
                  />
                )}
              </div>

              <div ref={selected}>
                <div className="selected">
                  <h4>Performance</h4>
                  <hr />

                  {indicatorDetails ? (
                    indicatorDetails && (
                      <SelectedIndicator
                        setLoading={setLoading}
                        indicatorDetails={indicatorDetails}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        url="indicators"
                        ID={ID}
                        partnerName={partnerName}
                      />
                    )
                  ) : (
                    <>
                      <p>Click on a user to see their details</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="div32">
            <Top
              code={ID}
              year={year}
              data={data}
              active={active}
              start={start}
              end={end}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>

        {loading && <Loading />}
      </div>

      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          partnerName={partnerName}
          selectedGoalID={selectedGoalID}
          selectedStrategicID={selectedStrategicID}
          selectedIntermediateID={selectedIntermediateID}
          selectedOutputID={selectedOutputID}
        />
      )}
      {loading && WaveLoading}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const description = useRef();
  const baseline = useRef();
  const target = useRef();
  const targetQ1 = useRef();
  const targetQ2 = useRef();
  const targetQ3 = useRef();
  const targetQ4 = useRef();

  const createBeneficiary = () => {
    const body = {
      Output_ID: props?.selectedOutputID,
      Description: description.current.getValue(),
      Baseline: baseline.current.getValue(),
      Target_Annual: target.current.getValue(),
      Target_Q1: targetQ1.current.getValue(),
      Target_Q2: targetQ2.current.getValue(),
      Target_Q3: targetQ3.current.getValue(),
      Target_Q4: targetQ4.current.getValue(),
    };

    setError("");
    setLoading(true);

    fetch(`/api/indicators/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to create ");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Define New Output</h3>
          <FontAwesomeIcon
            onClick={() => props.setClicked(false)}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <hr />
        <div className="new">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="">
              <Input ref={description} type="text" label="Indicator Name *" />
            </div>
            <div className="">
              <Input ref={baseline} type="text" label="Baseline Value *" />
              <Input ref={target} type="text" label="Annual Target *" />
            </div>
            <div className="">
              <Input ref={targetQ1} type="text" label="Quarter 1 Target *" />
              <Input ref={targetQ2} type="text" label="Quarter 2 Target *" />
            </div>
            <div className="">
              <Input ref={targetQ3} type="text" label="Quarter 3 Target *" />
              <Input ref={targetQ4} type="text" label="Quater 4 Target *" />
            </div>
            <h6>{error}</h6>
            <Button handleClick={createBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const Top = (props) => {
  const [isActive, setIsActive] = useState([true, false, false, false, false]);
  const [data, setData] = useState(null);

  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);

  const [selectedQuarter, setSelectedQuarter] = useState("Q1"); // Default to Q1

  const [targets, setTargets] = useState({
    Target_Q1: 0,
    Target_Q2: 0,
    Target_Q3: 0,
    Target_Q4: 0,
    Target_Annual: 0,
  });

  useEffect(() => {
    const updatedActiveDivs = isActive.map(
      (val, index) => index === props?.active - 2023
    );
    setIsActive(updatedActiveDivs);
  }, [props?.active]);

  useEffect(() => {
    if (props?.code && props?.start && props?.end) {
      getData(props.start, props.end, setData);
    }
  }, [props?.code, props?.start, props?.end]);

  useEffect(() => {
    const st1 = `${props?.active}-04-01`;
    const en1 = `${props?.active}-06-30`;
    const st2 = `${props?.active}-07-01`;
    const en2 = `${props?.active}-09-30`;
    const st3 = `${props?.active}-10-01`;
    const en3 = `${props?.active}-12-31`;
    const st4 = `${parseInt(props?.active) + 1}-01-01`;
    const en4 = `${parseInt(props?.active) + 1}-03-31`;

    // Fetch data for each quarter
    getData(st1, en1, (data) => setQuarterData(data, setQ1, "Q1"));
    getData(st2, en2, (data) => setQuarterData(data, setQ2, "Q2"));
    getData(st3, en3, (data) => setQuarterData(data, setQ3, "Q3"));
    getData(st4, en4, (data) => setQuarterData(data, setQ4, "Q4"));
  }, [props?.active, props?.code]);

  const setQuarterData = (data, setQuarter, quarter) => {
    let performance = 0;
    let newTargets = {
      Target_Q1: 0,
      Target_Q2: 0,
      Target_Q3: 0,
      Target_Q4: 0,
      Target_Annual: 0,
    };

    // Aggregate performance and targets based on the Date field
    data.forEach((item) => {
      performance += parseInt(item.Performance);
      newTargets.Target_Q1 += parseInt(item.Target_Q1 || 0);
      newTargets.Target_Q2 += parseInt(item.Target_Q2 || 0);
      newTargets.Target_Q3 += parseInt(item.Target_Q3 || 0);
      newTargets.Target_Q4 += parseInt(item.Target_Q4 || 0);
      newTargets.Target_Annual += parseInt(item.Target_Annual || 0);
    });

    // Update the state with the aggregated performance and targets
    setQuarter(performance);

    // Update targets state based on the selected quarter
    if (quarter === "Q1") {
      setTargets((prevTargets) => ({
        ...prevTargets,
        Target_Q1: newTargets.Target_Q1,
      }));
    } else if (quarter === "Q2") {
      setTargets((prevTargets) => ({
        ...prevTargets,
        Target_Q2: newTargets.Target_Q2,
      }));
    } else if (quarter === "Q3") {
      setTargets((prevTargets) => ({
        ...prevTargets,
        Target_Q3: newTargets.Target_Q3,
      }));
    } else if (quarter === "Q4") {
      setTargets((prevTargets) => ({
        ...prevTargets,
        Target_Q4: newTargets.Target_Q4,
      }));
    }
    setTargets((prevTargets) => ({
      ...prevTargets,
      Target_Annual: newTargets.Target_Annual,
    }));
  };

  function getData(st, en, setMyData) {
    props.setLoading(true);
    fetch(`/api/performance/single/${props?.code}/${st}/${en}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to fetch data");
      })
      .then((responseData) => {
        setMyData(responseData.data);
        props.setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        props.setLoading(false);
      });
  }

  // Event handler for clicking on a bar in the bar chart
  const handleBarClick = (quarter) => {
    setSelectedQuarter(quarter);
  };

  const calculatePercent = (performance, target) => {
    if (target === 0) return 0;
    const percent = performance / target;
    return percent > 1 ? 1 : percent; // Ensure it does not exceed 100%
  };

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2> Indicator Performance </h2>
          </div>
          <div className="outer">
            <div className="section">
              <h3>Yearly Distribution</h3>
              <QuarterlyBarChart
                data={[
                  { name: "Q1", value: q1 },
                  { name: "Q2", value: q2 },
                  { name: "Q3", value: q3 },
                  { name: "Q4", value: q4 },
                ]}
                aspect={1.6}
                color="#BA0C2F"
                onBarClick={handleBarClick} // Pass the event handler
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance Vs Target</h2>
          </div>
          <div className="outer">
            <div className="section">
              <h3>
                {props?.active} {selectedQuarter} Performance Vs {props?.active}{" "}
                {selectedQuarter} Target
              </h3>
              <GaugeChart
                className="gg"
                id="gauge-chart1"
                nrOfLevels={4}
                colors={["red", "orange", "blue", "green"]}
                arcWidth={0.3}
                percent={calculatePercent(
                  selectedQuarter === "Q1"
                    ? q1
                    : selectedQuarter === "Q2"
                    ? q2
                    : selectedQuarter === "Q3"
                    ? q3
                    : q4,
                  selectedQuarter === "Q1"
                    ? targets.Target_Q1
                    : selectedQuarter === "Q2"
                    ? targets.Target_Q2
                    : selectedQuarter === "Q3"
                    ? targets.Target_Q3
                    : targets.Target_Q4
                )}
                textColor="gray"
              />

              <div className="">
                <p className="bold">
                  Performance:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? q1
                      : selectedQuarter === "Q2"
                      ? q2
                      : selectedQuarter === "Q3"
                      ? q3
                      : q4
                  )}
                </p>
                <p className="bold">
                  Target:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? targets.Target_Q1
                      : selectedQuarter === "Q2"
                      ? targets.Target_Q2
                      : selectedQuarter === "Q3"
                      ? targets.Target_Q3
                      : targets.Target_Q4
                  )}
                </p>
                {data &&
                  (selectedQuarter === "Q1" && q1 / targets.Target_Q1 > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q1 / targets.Target_Q1) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q2" && q2 / targets.Target_Q2 > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q2 / targets.Target_Q2) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q3" && q3 / targets.Target_Q3 > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q3 / targets.Target_Q3) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q4" && q4 / targets.Target_Q4 > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q4 / targets.Target_Q4) * 100).toFixed(2)} %
                    </p>
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
            <div className="section">
              <h3>
                {props?.active} {selectedQuarter} Performance Vs Annual Target
              </h3>
              <GaugeChart
                className="gg"
                id="gauge-chart2"
                nrOfLevels={4}
                colors={["red", "orange", "blue", "green"]}
                arcWidth={0.3}
                percent={calculatePercent(
                  selectedQuarter === "Q1"
                    ? q1
                    : selectedQuarter === "Q2"
                    ? q2
                    : selectedQuarter === "Q3"
                    ? q3
                    : q4,
                  targets.Target_Annual
                )}
                textColor="gray"
              />

              <div className="">
                <p className="bold">
                  Performance:{" "}
                  {withCommas(
                    selectedQuarter === "Q1"
                      ? q1
                      : selectedQuarter === "Q2"
                      ? q2
                      : selectedQuarter === "Q3"
                      ? q3
                      : q4
                  )}
                </p>
                <p className="bold">
                  Target: {withCommas(targets.Target_Annual)}
                </p>

                {data &&
                  (selectedQuarter === "Q1" &&
                  q1 / targets.Target_Annual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q1 / targets.Target_Annual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q2" &&
                    q2 / targets.Target_Annual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q2 / targets.Target_Annual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q3" &&
                    q3 / targets.Target_Annual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q3 / targets.Target_Annual) * 100).toFixed(2)} %
                    </p>
                  ) : selectedQuarter === "Q4" &&
                    q4 / targets.Target_Annual > 1 ? (
                    <p className="bolds">
                      Actual Performance:{" "}
                      {((q4 / targets.Target_Annual) * 100).toFixed(2)} %
                    </p>
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {props.loading && WaveLoading}
      </div>
    </>
  );
};
